<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { NuxtLink } from '#components'

export type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'text' | 'dark' | 'danger' | 'on-primary-bg' | 'on-accent-bg' | 'sidebar' | 'accent'
export type ButtonSize = 'md' | 'lg'
export type ButtonColor = 'primary' | 'secondary' | 'system'
export type AnimateIcon = 'spinCcw'

interface ButtonProps {
  name: string
  variant?: ButtonVariant
  size?: ButtonSize
  prefixIcon?: string
  suffixIcon?: string
  iconOnly?: boolean
  loading?: boolean
  disabled?: boolean
  to?: RouteLocationRaw
  animateIcon?: AnimateIcon
}

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: 'primary',
  size: 'md',
})

const variantGeneralClasses: { [key in ButtonVariant]: string } = {
  'primary': 'fbutton-primary',
  'secondary': 'fbutton-secondary',
  'outline': 'fbutton-outline',
  'text': 'fbutton-text',
  'dark': 'fbutton-dark',
  'danger': 'fbutton-danger',
  'on-primary-bg': 'fbutton-on-primary-bg',
  'on-accent-bg': 'fbutton-on-accent-bg',
  'sidebar': 'fbutton-sidebar',
  'accent': 'fbutton-accent',
}

const sizeClasses: { [key in ButtonSize]: string } = {
  md: `fubex-rounded-md  ${props.iconOnly ? 'p-8px' : 'p-8px'} paragraph-sm flex-none`,
  lg: `fubex-rounded-lg ${props.iconOnly ? 'p-14px' : 'px-16px py-12px'} paragraph-md`,
}

const disabledClassses: { [key in ButtonVariant]: string } = {
  'primary': 'fbutton-primary-disabled',
  'secondary': 'fbutton-secondary-disabled',
  'outline': 'fbutton-outline-disabled',
  'text': 'fbutton-text-disabled',
  'dark': 'fbutton-dark-disabled',
  'danger': 'fbutton-danger-disabled',
  'on-primary-bg': 'fbutton-on-primary-bg-disabled',
  'on-accent-bg': 'fbutton-on-accent-bg-disabled',
  'sidebar': 'fbutton-sidebar-disabled',
  'accent': 'fbutton-accent-disabled',
}

const classes = computed(() => ` ${sizeClasses[props.size]} ${props.disabled ? disabledClassses[props.variant] : variantGeneralClasses[props.variant]}`)

const isAnimateIconActive = ref(false)

function handleClick () {
  if (props.animateIcon) {
    isAnimateIconActive.value = true
    setTimeout(() => {
      isAnimateIconActive.value = false
    }, 500) // Doba trvání animace v milisekundách
  }
}
</script>

<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :data-test="getCypressId(name)"
    :to="to"
    type="button"
    :tabindex="disabled ? -1 : 0" inline-flex whitespace-nowrap justify-center items-between relative focus:fubex-focus-ring :class="[disabled && 'pointer-events-none', classes]"
    :disabled="disabled" v-bind="$attrs"
    transition-all duration-250
    overflow-hidden
    @click="handleClick"
  >
    <div
      flex items-center justify-center gap-x-8px
    >
      <template v-if="!!$slots.prefix || prefixIcon">
        <slot v-if="!!$slots.prefix" name="prefix" />
        <div
          v-if="prefixIcon"
          :class="[
            prefixIcon,
            { spinCcw: isAnimateIconActive },
          ]"
          h-16px w-16px
        />
      </template>
      <slot />
      <template v-if="!!$slots.suffix || suffixIcon">
        <slot v-if="!!$slots.suffix" name="suffix" />
        <div
          v-if="suffixIcon"
          :class="[
            suffixIcon,
            { spinCcw: isAnimateIconActive },
          ]"
          h-16px w-16px
        />
      </template>
      <div v-if="loading" absolute bg-neutral-300 text-neutral-700 top-0 left-0 w-full h-full flex justify-center items-center opacity-95>
        <UnoIcon i-fubex-loading w-24px h-24px />
      </div>
    </div>
  </component>
</template>

<style scoped>
.spinCcw{
  animation: spin 0.5s ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}
</style>
